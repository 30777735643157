<template>
  <div class="col-12 px-0 pb-5">
    <div id="services" class="w-100 services pageTitle">
      <parallax>
        <img src="/assets/img/krave-studios-homepic-mod.jpg" alt="" />
      </parallax>
      <h1 class="pageTitle">Services</h1>
    </div>
    <div class="w-100 service-container">
      <div class="row pt-5">
        <Service
          v-for="(service, index) in services"
          :key="index"
          :service="service"
        ></Service>
      </div>
    </div>
  </div>
</template>
<script>
import Parallax from "vue-parallaxy";
export default {
  name: "Services",
  components: {
    Parallax,
  },
  computed: {
    services() {  return this.$store.state.services; },
    footer() { return this.$store.state.footer }
  },
  mounted() {
    this.$store.commit("SET_FOOTER", true);
    this.$store.dispatch('getServices');
  }
};
</script>
<style scoped>
@import "../css/Services.scss";
</style>
