import { render, staticRenderFns } from "./Services.vue?vue&type=template&id=da268f78&scoped=true&"
import script from "./Services.vue?vue&type=script&lang=js&"
export * from "./Services.vue?vue&type=script&lang=js&"
import style0 from "./Services.vue?vue&type=style&index=0&id=da268f78&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "da268f78",
  null
  
)

export default component.exports